<template>
    <client-page>
        <page-section class="page-section--first page-section--last grey lighten-5">
            <h2 class="login-title"><span>비밀번호 찾기</span></h2>
            <div class="login-container">
                <v-card outlined class="mb-20">
                    <v-card-title> 사이트 리뉴얼 안내 </v-card-title>
                    <v-card-text> ※ 이전 용산시시제품 제작소 이용자는 아이디와 이메일 모두 이메일을 입력해주세요 </v-card-text>
                </v-card>
                <v-text-field v-model="username" placeholder="아이디" outlined hide-details class="mb-8 mb-md-12" />
                <v-row class="row--xx-small">
                    <v-col>
                        <v-text-field v-model="email" type="email" placeholder="이메일" outlined hide-details />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn type="submit" color="grey" dark class="h-100" @click="certify"><span class="font-size-14">인증번호전송</span></v-btn>
                    </v-col>
                </v-row>
                <v-text-field v-model="otp" type="number" placeholder="인증번호 입력" outlined hide-details class="mt-8 mt-md-12" />
                <v-btn type="submit" x-large rounded block color="secondary" class="mt-20 mt-md-40" @click="recovery">확인</v-btn>
            </div>
        </page-section>
        <!-- <find-password-info /> -->
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import FindPasswordInfo from "./find-password-info.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FindPasswordInfo,
    },
    data() {
        return {
            username: null,
            email: null,
            otp: null,
        };
    },
    methods: {
        validate(recovery = false) {
            try {
                if (!this.username) throw new Error("아이디를 입력해주세요");
                if (!this.email) throw new Error("이메일을 입력해주세요");
                if (recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async certify() {
            if (this.validate()) {
                await api.v1.users.certify.post({
                    type: "email",
                    kind: "password",
                    username: this.username,
                    email: this.email,
                });

                alert("인증번호가 전송되었습니다");
            }
        },

        async recovery() {
            if (this.validate(true)) {
                await api.v1.users.recovery.post({
                    type: "email",
                    kind: "password",
                    email: this.email,
                    username: this.username,
                    otp: this.otp,
                });

                alert("가입한 이메일 정보로 비밀번호가 발송되었습니다");
            }
        },
    },
};
</script>
