<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <h2 class="login-title"><span>로그인</span></h2>
            <div class="login-container">
                <v-text-field v-model="username" placeholder="아이디" outlined hide-details class="mb-8 mb-md-12" />
                <v-text-field type="password" v-model="password" placeholder="비밀번호" outlined hide-details @keydown.enter="login" class="mb-8 mb-md-12" />
                <v-row class="row--x-small">
                    <v-col>
                        <v-checkbox v-model="remember" :value="true" color="secondary" label="아이디 저장" hide-details />
                    </v-col>
                </v-row>
                <v-btn x-large block color="primary" @click="login" class="mb-8 mb-md-12 mt-20 mt-md-40 rounded-lg">로그인</v-btn>
                <v-row no-gutters justify="center" align="center" class="ma-n4 ma-md-n6">
                    <v-col cols="auto" class="pa-4 pa-md-6">
                        <v-btn to="/login/find-id/" text small color="grey darken-4">아이디 찾기</v-btn>
                    </v-col>
                    <v-col cols="auto" class="pa-4 pa-md-6">
                        <v-divider vertical class="grey lighten-4" style="height: 12px" />
                    </v-col>
                    <v-col cols="auto" class="pa-4 pa-md-6">
                        <v-btn to="/login/find-password/" text small color="grey darken-4">비밀번호 찾기</v-btn>
                    </v-col>
                    <v-col cols="auto" class="pa-4 pa-md-6">
                        <v-divider vertical class="grey lighten-4" style="height: 12px" />
                    </v-col>
                    <v-col cols="auto" class="pa-4 pa-md-6">
                        <v-btn to="/join/agreements" text small color="grey darken-4">회원가입</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <!-- 안내팝업 -->
        <!-- <main-info /> -->
    </client-page>
</template>

<script>
import CryptoAES from "@/plugins/crypto-aes";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";
import MainInfo from "./main-info.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        KakaoLogin,
        MainInfo,
    },
    data() {
        return {
            username: "",
            password: "",

            remember: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            // if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.push("/");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-btn.v-btn--text.v-size--small {
        height: auto;
        padding: 6px;
        margin: -6px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    .theme--light.v-btn--active:not(:hover):before {
        opacity: 0;
    }
    .theme--light.v-btn--active:hover:before {
        opacity: 0.04;
    }
}
</style>