var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last grey lighten-5"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("아이디 찾기")])]), _c('div', {
    staticClass: "login-container"
  }, [_c('v-row', {
    staticClass: "row--xx-small"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "email",
      "placeholder": "이메일",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100",
    attrs: {
      "type": "submit",
      "color": "grey",
      "dark": ""
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("인증번호전송")])])], 1)], 1), _c('v-text-field', {
    staticClass: "mt-8 mt-md-12",
    attrs: {
      "type": "number",
      "placeholder": "인증번호 입력",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  }), _c('v-btn', {
    staticClass: "mt-20 mt-md-40",
    attrs: {
      "type": "submit",
      "x-large": "",
      "rounded": "",
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("확인")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }